
const leadList = (item: any, mapper?: any) => {
    const getName = () => {
        return (item.first_name + " " + item.last_name).trim()
    }
    return {
        id: item.id || item[mapper?.id],
        name: getName(),
        number: item.phone,
        email: item.email,
    }
}
export { leadList };