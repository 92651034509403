import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import DashboardService from "../services/helpers/Dashboard.service";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { getStatsFailed, getStatsSuccess } from "./dashboard.slice";
import { statsFilterType } from "./interface.s.m";

function* dashboard() {
    yield takeEvery(`${sliceName.dashboard}/getStats`, getStatsSaga);
}
function* getStatsSaga({ payload }: CustomPayload<statsFilterType>): Generator {
    const result = yield call(DashboardService.getStats, payload);
    if (!result) {
        yield put(getStatsFailed());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(getStatsSuccess(body));
    } else {
        yield put(getStatsFailed());
    }
}

export default function* dashboardSaga() {
    yield all([dashboard()]);
}
