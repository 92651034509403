import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { createNewChatbotFailed, createNewChatbotSuccess, isGettingError, isGettingSuccess } from "./chatbot.slice";
import ChatbotService from "../services/helpers/Chatbot.service";
import { toastError, toastLoading, toastSuccess } from "../../../packages/hot-toast/helper.p";
import { updateChatbotBalance } from "../../packages/states/package.slice";

function* chatbot() {
    yield takeEvery(`${sliceName.chatbot}/isGetting`, isGettingSaga);
    yield takeEvery(`${sliceName.chatbot}/createNewChatbot`, createNewChatbotSaga);
}
function* isGettingSaga({ payload }: CustomPayload<any>): Generator {
    const result = yield call(ChatbotService.getChatbot, payload);
    if (!result) {
        yield put(isGettingError());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(isGettingSuccess(body));
    } else {
        yield put(isGettingError());
    }
}
function* createNewChatbotSaga({ payload }: CustomPayload<any>): Generator {
    const toastId = toastLoading({ message: "Please wait..." })
    const result = yield call(ChatbotService.createNewChatbot, { name: payload.title });
    if (!result) {
        toastError({ message: "Something went wrong", id: toastId })
        yield put(createNewChatbotFailed());
        return;
    }
    const { body, status, message } = result as apiResponse;
    if (status) {
        toastSuccess({ message: "Chatbot create successfully. Redirecting to details...", id: toastId })
        yield put(createNewChatbotSuccess(body));
        yield put(updateChatbotBalance(-1));
    } else {
        toastError({ message: message, id: toastId })
        yield put(createNewChatbotFailed());
    }
}

export default function* chatbotSaga() {
    yield all([chatbot()]);
}
