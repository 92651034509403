import React from "react";
import { leftSideBarMenuProps } from "../interface/private.interface.m";

const EachMenu = ({ styles, item, isSelected, onClick }: leftSideBarMenuProps) => {
    return (
        <div className={`${styles.menu}`} onClick={onClick}>
            <span className={`${isSelected ? styles.selected : {}}`} >{item.title}</span>
        </div>
    )
};
export default React.memo(EachMenu);