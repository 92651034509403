import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { checkEmailForRegProps, loginProps } from "../services/interface.service.m";
import AuthService from "../services/helpers/Auth.service";
import { tryLoginFailed, tryLoginSuccess, tryRegistrationFailed, tryRegistrationSuccess } from "./auth.slice";
import { toastError, toastLoading, toastSuccess } from "../../../packages/hot-toast/helper.p";
import { setRegistrationData } from "../../../helper/cookie/cookie";

function* auth() {
    yield takeEvery(`${sliceName.auth}/tryLogin`, tryLoginSaga);
    yield takeEvery(`${sliceName.auth}/tryRegistration`, tryRegistrationSaga);
}
function* tryLoginSaga({ payload }: CustomPayload<loginProps>): Generator {
    const toastId = toastLoading({ message: "Please wait..." })
    const result = yield call(AuthService.tryLogin, payload);
    if (!result) {
        toastError({ message: "Something went wrong", id: toastId })
        yield put(tryLoginFailed());
        return;
    }
    const { body, status, message } = result as apiResponse;
    if (status) {
        toastSuccess({ message: "Login successfully. Redirecting to dashboard...", id: toastId })
        /* get profile data */
        /* get package info */
        yield put(tryLoginSuccess(body));
    } else {
        toastError({ message: message, id: toastId })
        yield put(tryLoginFailed());
    }
}
function* tryRegistrationSaga({ payload }: CustomPayload<checkEmailForRegProps>): Generator {
    const toastId = toastLoading({ message: "Please wait..." })
    const result = yield call(AuthService.tryRegistration, payload);
    if (!result) {
        toastError({ message: "Something went wrong", id: toastId })
        yield put(tryRegistrationFailed());
        return;
    }
    const { status, message } = result as apiResponse;
    if (status) {
        toastSuccess({ message: "It will take a few moments to complete your registration. Please try login after some time", id: toastId })
        yield setRegistrationData(payload);
        yield put(tryRegistrationSuccess({success: true}));
    } else {
        if(typeof message === 'object' && (message as any).email){
            toastError({ message: (message as any).email, id: toastId })
        }
        else{
            toastError({ message: message, id: toastId })
        }
        yield put(tryRegistrationFailed());
    }
}

export default function* authSaga() {
    yield all([auth()]);
}
