const apiStatus = {
    internalError: 'internal',
    invalidCredential: 1001,
    validation: 1002,
    tokenInvalid: 1003,
    tokenExpired: 1004,
    timeoutError: 'time-out',
    internetError: 'no-internet',
    unauthorized: 401,
    appUnauthorized: 4001,
};

export const messages = {
    noToken: '',
    timeout: '',
    internet: '',
    internalError: 'Internal Server Error',
};

export { apiStatus }
