import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { getCompanyInstructionFailed, getCompanyInstructionSuccess, updateCompanyInstructionFailed, updateCompanyInstructionSuccess } from "./companyInformation.slice";
import CompanyInformationService from "../services/helpers/CompanyInformation.service";

function* companyInformation() {
    yield takeEvery(`${sliceName.companyInformation}/getCompanyInstruction`, getCompanyInstructionSaga);
    yield takeEvery(`${sliceName.companyInformation}/updateCompanyInstruction`, updateCompanyInstructionSaga);
}

function* getCompanyInstructionSaga(): Generator {
    const result = yield call(CompanyInformationService.getCompanyInformation);
    if (!result) {
        yield put(getCompanyInstructionFailed());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(getCompanyInstructionSuccess(body));
    } else {
        yield put(getCompanyInstructionFailed());
    }
}
function* updateCompanyInstructionSaga({ payload }: CustomPayload<any>): Generator {
    const result = yield call(CompanyInformationService.updateCompanyInformation, {instruction: payload});
    if (!result) {
        yield put(updateCompanyInstructionFailed());
        return;
    }
    const { status } = result as apiResponse;
    if (status) {
        yield put(updateCompanyInstructionSuccess(payload));
    } else {
        yield put(updateCompanyInstructionFailed());
    }
}

export default function* companyInformationSaga() {
    yield all([companyInformation()]);
}
