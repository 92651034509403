import { lazy } from "react";
import Loadable from "../suspense-fallback/Loadable.suspense";
import { parentRoute } from "../interface.route";
import consoleRouteName from "../consoleRouteName.router";
import ConversationFallback from "../suspense-fallback/Conversation.fallback";

const CompanyInformationIndex = Loadable(lazy(() => import('../../modules/company-information/Index.m')), ConversationFallback);

const companyRoutes: Array<parentRoute> = [
    {
        path: consoleRouteName.company.path,
        navigate: consoleRouteName.company.navigate,
        element: CompanyInformationIndex,
        index: false,
        accessLevel: 0,
        title: "Company information"
    }
]
export default companyRoutes;
