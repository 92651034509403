import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import commonStates from "../../../states/commonState.state";
import commonReducers from "../../../states/commonReducer.state";

const initialState: initialStateP = {
    ...commonStates,
    /* create new conversation */
    isSaving: false,
    newItem: null
}

const conversationSlice: any = createSlice({
    name: sliceName.conversation,
    initialState: initialState,
    reducers: {
        ...commonReducers
    },
});

export const {
    isGetting,
    isGettingSuccess,
    isGettingError,
    isGettingMore,
    isRefresh,
    isStateUpdate,
    pageChange,
    perPageChange,
    queryChange,
}: reducerFunctions = conversationSlice.actions;

export default conversationSlice.reducer;
