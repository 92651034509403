import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";
import { setApiToken, setLoginData, setProfileData } from "../../../helper/cookie/cookie";

const initialState: initialStateP = {
    isLoggedIn: false,
    profileData: null,
    userRole: '',
    accessToken: '',
    /* login */
    email: '',
    password: '',
    isLogging: false,
    /* for registration */
    regEmail: '',
    regPassword: '',
    regConfirmPassword: '',
    isSubmittingReg: false,
    isGotoPackageScreen: false,
}

const authSlice: any = createSlice({
    name: sliceName.auth,
    initialState: initialState,
    reducers: {
        storeLoginForm: (state: initialStateP, { payload }: CustomAction<any>) => {
            (state as any)[payload.name] = payload.value;
        },
        tryLogin: (state: initialStateP) => {
            state.isLogging = true;
        },
        tryLoginSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            setApiToken(payload.access_token);
            setProfileData(payload.user);
            setLoginData({ email: state.email, password: state.password });
            state.accessToken = payload.access_token;
            state.profileData = payload.user;
            state.isLogging = false;
            state.isLoggedIn = true;
        },
        tryLoginFailed: (state: initialStateP) => {
            state.isLogging = false;
        },
        storeAuthFromCookie: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.accessToken = payload.token;
            state.profileData = payload?.profileData || state.profileData;
            state.isLoggedIn = payload?.isLoggedIn || state.isLoggedIn;
        },
        resetLogin: (state: initialStateP) => {
            state.isLoggedIn = false;
            state.accessToken = '';
            state.profileData = null;
        },
        unmountStore: (state: initialStateP) => {
            for (const property in initialState) {
                (state as any)[property] = (initialState as any)[property];
            }
        },
        /* for registration */
        storeRegistrationForm: (state: initialStateP, { payload }: CustomAction<any>) => {
            (state as any)[payload.name] = payload.value;
        },
        tryRegistration: (state: initialStateP) => {
            /* do necessary stuff for registration */
            state.isSubmittingReg = true;
            state.isGotoPackageScreen = false;
        },
        tryRegistrationSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            console.log("payload", payload);
            state.isSubmittingReg = false;
            if(payload.success){
                state.isGotoPackageScreen = true;
            }
        },
        tryRegistrationFailed: (state: initialStateP) => {
            state.isSubmittingReg = false;
            state.isGotoPackageScreen = false;
        },
    },
});

export const {
    storeLoginForm,
    tryLogin,
    tryLoginSuccess,
    tryLoginFailed,
    resetLogin,
    unmountStore,
    storeAuthFromCookie,
    /* for registration */
    storeRegistrationForm,
    tryRegistration,
    tryRegistrationSuccess,
    tryRegistrationFailed
}: reducerFunctions = authSlice.actions;

export default authSlice.reducer;
