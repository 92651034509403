/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {  tableProps } from "../../../assets/tables/conversation.table";
import LoadingData from "./LoadingData.m";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useLayoutEffect, useState } from "react";
import LeadService from "../services/helpers/Lead.service";
import EmptyLead from "./EmptyLead.m";
import EachLead from "./EachLead.m";
import { leadList } from "../../../controllers/formatters/lead.formatter";
import { leadTableHeader } from "../../../assets/tables/lead.table";
import styles from '../css/lead.module.css';

const LeadList = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading]= useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0)

    useLayoutEffect(() => {
        getLeadList();
    }, []);
    const getLeadList = async () => {
        const result = await LeadService.getList({page: page});
        if(!result){
            setIsLoading(false);
            return;
        }
        const {status, body} = result;
        if(status){
            setList(body);
            // if(page === 1){
            //     setTotalPage(body.extra_data)
            // }
        }
        setIsLoading(false);
    };

    const _renderTableHeader = () => {
        return leadTableHeader.map((item: tableProps, index: number) => (
            <th key={index} style={{ textAlign: item.align, ...item.style }} {...item.extra}>{item.title}</th>
        ))
    };
    const _renderTableBody = () => {
        return list.map((listItem: any, listItemIndex: number) => {
            return <EachLead item={leadList(listItem)} key={`${listItemIndex}-${listItem.id}`} index={listItemIndex} styles={styles} />
        })
    }
    return (
        <div className={styles.tableContainer}>
            <table className="" id="conversation-list" style={{ width: '100%' }}>
                <thead>
                    <tr className={styles.tr}>{_renderTableHeader()}</tr>
                </thead>
                <tbody>
                    {isLoading && <LoadingData />}
                    {(!isLoading && list.length === 0) && <EmptyLead length={leadTableHeader.length} />}
                    {(!isLoading && list.length > 0) && _renderTableBody()}
                </tbody>
            </table>
            <ResponsivePagination
                current={page}
                total={totalPage}
                onPageChange={setPage}
            />
        </div>
    );
};

export default LeadList;