import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { _package, _packageReducerFunctions, _packageSlice } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";

const initialState: _packageSlice = {
    isLoading: false,
    list: [],
    selected: null,
    selectedIndex: null,
    /* previous subscribe package */
    isGettingUserPackage: true,
    subscribedPackage: null,
    isUpdating: false,
    isGotoDashboard: false,
    /* auto recharge */
    isLoadingAutoRecharge: false,
    isActiveAutoRecharge: false,
    autoRechargeAmount: 0,
    autoRechargeThrottle: 0,
    isSavingAutoRecharge: false,
    /* recurring */
    isLoadingRecurring: false,
    isActiveRecurring: false,
    isSavingRecurring: false,
    /* credit recharge */
    creditRechargeAmount: 0,
    isUpdatingCreditRecharge: false,
    /* package credit */
    currentBalanceInfo: null
}

const packageSlice: any = createSlice({
    name: sliceName.package,
    initialState: initialState,
    reducers: {
        isGettingPackageList: (state: _packageSlice) => {
            state.isLoading = true;
        },
        isGettingPackageListSuccess: (state: _packageSlice, {payload}: CustomAction<any>) => {
            state.list = payload.data;
            state.isLoading = false;
        },
        isGettingPackageListFailed: (state: _packageSlice) => {
            state.isLoading = false;
        },
        storeSelectedPackage: (state: _packageSlice, {payload}: CustomAction<{item: _package, index: number}>) => {
            state.selected = payload.item;
            state.selectedIndex = payload.index;
        },
        getUserPackage: (state: _packageSlice) => {
            state.isGettingUserPackage = true;
        },
        getUserPackageSuccess: (state: _packageSlice, {payload}: CustomAction<any>) => {
            state.subscribedPackage = payload;
            state.isGettingUserPackage = false;
        },
        getUserPackageFailed: (state: _packageSlice) => {
            state.isGettingUserPackage = false;
        },
        updatingPackage: (state: _packageSlice) => {
            state.isUpdating = true;
        },
        updatingPackageSuccess: (state: _packageSlice) => {
            /* do some other stuff */
            state.isUpdating = false;
        },
        updatingPackageFailed: (state: _packageSlice) => {
            state.isUpdating = false;
        },
        resetLogin: (state: _packageSlice) => {},
        unmountStore: (state: _packageSlice) => {
            for (const property in initialState) {
                (state as any)[property] = (initialState as any)[property];
            }
        },
        goToDashboard: (state: _packageSlice, {payload}: CustomAction<any>) => {
            state.isGotoDashboard = payload;
        },
        getUserCredit: (state: _packageSlice) => {},
        getUserCreditSuccess: (state: _packageSlice, {payload}: CustomAction<any>) => {
            state.currentBalanceInfo = payload;
        },
        updateChatbotBalance: (state: _packageSlice, {payload}: CustomAction<any>) => {
            state.currentBalanceInfo = {...state.currentBalanceInfo, current_balance: Number(state.currentBalanceInfo.current_balance) + payload};
        },
    },
});

export const {
    isGettingPackageList,
    isGettingPackageListSuccess,
    isGettingPackageListFailed,
    getUserPackage,
    getUserPackageSuccess,
    getUserPackageFailed,
    updatingPackage,
    updatingPackageSuccess,
    updatingPackageFailed,
    resetLogin,
    unmountStore,
    goToDashboard,
    getUserCredit,
    getUserCreditSuccess,
    updateChatbotBalance
}: _packageReducerFunctions = packageSlice.actions;

export default packageSlice.reducer;
