import { commonStatesP } from "./interface.state";

const commonStates: commonStatesP = {
    list: [],
    isLoading: true,
    page: 1,
    perPage: 20,
    hasMore: false,
    isLoadingMore: false,
    refreshing: false,
    order: "asc",
    query: "",
    hasInfiniteScroll: false,
    itemAppendDirection: 'last',
    firstRender: false,
};

export default commonStates;