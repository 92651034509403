import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import publicViewEndPoint from "../apiEndPoint.service.m";
import { creteNewConversationProps, getDetailsProps } from "../interface.service.m";

class PublicViewServiceClass {
    async getPublicViewDetails(payload: getDetailsProps) {
        return rootApi('GET_WITHOUT_AUTH', publicViewEndPoint.getPublicViewDetails + payload.id);
    }
    async createNewConversation(payload: creteNewConversationProps) {
        return rootApi('POST_FROM_DATA_WITHOUT_AUTH', publicViewEndPoint.createNewConversation, payload);
    }
}

const PublicViewService = new PublicViewServiceClass();
export default PublicViewService;