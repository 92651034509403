import "./style.css";
const CoreSkeleton = ({
  height = "10px",
  width = "10px",
  borderRadius = "0",
  style = {},
}) => {
  return (
    <div
      className="gl_skelton_wr"
      style={{
        backgroundColor: "#ddd",
        height: height,
        width: width,
        margin: "2px",
        borderRadius: borderRadius,
        ...style,
      }}
    ></div>
  );
};
export default CoreSkeleton;
