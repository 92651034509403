import CoreSkeleton from "../../../app-component/skeleton/CoreSkeleton";

const LoadingData = () => {
    return (
        <>
            {Array(10).fill(0).map((_, index) => (
                <tr key={index}>
                    <td><CoreSkeleton height="14px" width="105px" /></td>
                    <td><CoreSkeleton height="14px" width="45px" style={{ margin: '0 auto' }} /></td>
                    <td><CoreSkeleton height="14px" width="135px" style={{ margin: '0 auto' }} /></td>
                </tr>
            ))}

        </>
    );
};

export default LoadingData;