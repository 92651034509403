export interface tableProps {
    title: string;
    hasCustomRender?: boolean;
    valueField?: string;
    align?: 'center' | 'left' | 'right';
    style?: any;
    extra?: any;
}

const leadTableHeader: Array<tableProps> = [
    {
        title: 'Name',
        hasCustomRender: false,
        valueField: 'name',
        align: 'left'
    },
    {
        title: 'Number',
        hasCustomRender: false,
        valueField: 'number',
        align: 'center'
    },
    {
        title: 'Email',
        hasCustomRender: false,
        valueField: 'email',
        align: 'center'
    },
];

export { leadTableHeader };