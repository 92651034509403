import { baseURL, moduleName } from "../../../controllers/api/globalEndpoint.api";

const authEndPoint = {
    tryLogin: baseURL + moduleName.auth + '/login',
    getProfileData: baseURL + moduleName.auth + '/profile-data',
    /* for registration */
    isRegEmailIsExist: baseURL + moduleName.auth + '/check/email-exits',
    tryRegistration: baseURL + moduleName.auth + '/registration',
};

export default authEndPoint;