import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { isGettingError, isGettingSuccess } from "./conversation.slice";
import ConversationService from "../services/helpers/Conversation.service";

function* conversation() {
    yield takeEvery(`${sliceName.conversation}/isGetting`, isGettingSaga);
}
function* isGettingSaga({ payload }: CustomPayload<any>): Generator {
    const result = yield call(ConversationService.getConversationUniqueList, payload);
    if (!result) {
        yield put(isGettingError());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(isGettingSuccess(body));
    } else {
        yield put(isGettingError());
    }
}

export default function* conversationSaga() {
    yield all([conversation()]);
}
