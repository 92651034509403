import { all } from "redux-saga/effects";
import dashboardSaga from "../modules/dashboard/states/dashboard.saga";
import chatbotSaga from "../modules/chatbot/states/chatbot.saga";
import chatbotDetailsSaga from "../modules/chatbot-details/states/chatbotDetails.saga";
import authSaga from "../modules/auth/states/auth.saga";
import publicViewSaga from "../modules/chatbot-public-view/states/publicView.saga";
import conversationSaga from "../modules/conversation/states/conversation.saga";
import conversationHistorySaga from "../modules/conversation-details/states/conversationHistory.saga";
import companyInformationSaga from "../modules/company-information/states/companyInformation.saga";
import packageSaga from "../modules/packages/states/package.saga";

export default function* rootSaga() {
    yield all([
        authSaga(),
        dashboardSaga(),
        chatbotSaga(),
        chatbotDetailsSaga(),
        publicViewSaga(),
        conversationSaga(),
        conversationHistorySaga(),
        companyInformationSaga(),
        packageSaga(),
    ]);
}
