const consoleRouteName = {
    root: { path: '/', navigate: '' },
    console: { path: 'console', navigate: '/console' },
    login: { path: '/login', navigate: '/login' },
    chatbot: { path: 'chatbot', navigate: '/console/chatbot' },
    chatbotDetails: { path: 'chatbot/details/:id', navigate: '/console/chatbot/details/:id', childNavigate: 'details/:id', replacer: ':id' },
    chatbotPublicPreview: { path: 'public-view/chatbot/:key', navigate: '/public-view/chatbot/:key' },
    conversation: { path: 'conversation', navigate: '/console/conversation' },
    conversationDetails: { path: 'conversation/details/:unique', navigate: '/console/conversation/details/:unique', childNavigate: 'details/:unique', replacer: ':unique' },
    leadList: { path: 'lead', navigate: '/console/lead' },
    company: { path: 'company', navigate: '/console/company' },
    /* registration */
    registration: { path: '/registration', navigate: '/registration' },
    publicPackage: { path: '/registration/package', navigate: '/registration/package' },
    /* subscription */
    subscription: { path: '/subscription', navigate: '/subscription' },
    credit: { path: '/credit', navigate: '/credit' },
    /* profile */
    profile: { path: '/my-profile', navigate: '/my-profile' },
};
export default consoleRouteName;