import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import packageEndPoint from "../apiEndPoint.service.m";
// import dummyData from "../../../../controllers/dummy-data/dummyData";
// import { dummyDataFormat } from "../../../../controllers/dummy-data/dummyDataFormat";
// import { sleepHook } from "../../../../helper/hooks/sleep.hook";
import { _buyPackageProps, _getPackageProps } from "../interface.service.m";

class PackageServiceClass {
    async getPackageList({access}: _getPackageProps) {
        if(access === 'public'){
            return rootApi('GET_WITHOUT_AUTH', packageEndPoint.getPackageList);
        }
        else {
            return rootApi('GET', packageEndPoint.getPackageList);
        } 
    }
    async getUserSelectedPackage() {
        // if (process.env.REACT_APP_MODE !== 'PRODUCTION') {
        //     await sleepHook(() => { }, 2000);
        //     const object = {
        //         ...dummyData.package['package/get-user-package']
        //     };
        //     return dummyDataFormat(object);
        // }
        return rootApi('GET', packageEndPoint.getUserPackage);
        
    }
    async buyPackage(payload: _buyPackageProps) {
        if(payload.access === 'public'){
            return rootApi('POST_WITHOUT_AUTH', packageEndPoint.buyPackage, payload);
        }
        else {
            return rootApi('POST', packageEndPoint.buyPackage, payload);
        }
    }
    async getUserCreditBalance() {
        /* 
        const PACKAGE_LIMIT__BALANCE__KEY__CREDIT_AMOUNT = 1;
        const PACKAGE_LIMIT__BALANCE__KEY__CHAT_LIMIT = 2;
        */
        return rootApi('POST', `${packageEndPoint.creditBalance}`, {key: 2});
        
    }
}

const PackageService = new PackageServiceClass();
export default PackageService;