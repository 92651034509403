const sliceName = {
    auth: "auth-slice",
    dashboard: "dashboard-slice",
    chatbot: "chatbot-slice",
    chatbotDetails: "chatbot-details-slice",
    publicView: "public-view-slice",
    conversation: "conversation-slice",
    conversationHistory: "conversation-history-slice",
    companyInformation: "company-information-slice",
    package: "package-slice",

};
export default sliceName;