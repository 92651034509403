const EmptyLead = ({ length }: any) => {
    return (
        <tr>
            <td colSpan={length}>
                <div style={{ width: '100%', height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    No lead found !
                </div>
            </td>
        </tr>

    );
};

export default EmptyLead;