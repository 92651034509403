const baseURL = process.env.REACT_APP_NODE_API_BASE_URL;

const moduleName = {
    auth: '/user',
    dashboard: '/dashboard',
    chatbot: '/chatbot',
    publicApi: '/public-api',
    package: '/user/package',
    invoice: '/user/invoice',
    recharge: '/user/recharge'
};

export { moduleName, baseURL }