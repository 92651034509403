import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import chatbotEndPoint from "../apiEndPoint.service.m";
import { createChatbotP, deleteChatbotItemP } from "../interface.service.m";

class ChatbotServiceClass {
    async getChatbot(payload: any) {
        return rootApi('GET', chatbotEndPoint.getChatbotList);
    }
    async deleteChatbotItem(payload: deleteChatbotItemP) {
        // if (process.env.REACT_APP_MODE !== 'PRODUCTION') {
        //     await sleepHook(() => { }, 3000);
        //     const object = {
        //         ...dummyData.chatbot['chatbot/delete-item']
        //     };
        //     return dummyDataFormat(object);
        // }
        return rootApi('DELETE', chatbotEndPoint.deleteChatbotItem + payload);
    }
    async createNewChatbot(payload: createChatbotP) {
        return rootApi('POST', chatbotEndPoint.createChatbot, payload);
    }
}

const ChatbotService = new ChatbotServiceClass();
export default ChatbotService;