import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import commonStates from "../../../states/commonState.state";
import commonReducers from "../../../states/commonReducer.state";

const initialState: initialStateP = {
    ...commonStates
}

const conversationHistorySlice: any = createSlice({
    name: sliceName.conversationHistory,
    initialState: initialState,
    reducers: {
        ...commonReducers,
        unmountStore: (state: initialStateP) => {
            for (const property in initialState) {
                (state as any)[property] = (initialState as any)[property];
            }
        },
    },
});

export const {
    isGetting,
    isGettingSuccess,
    isGettingError,
    isGettingMore,
    isRefresh,
    isStateUpdate,
    pageChange,
    perPageChange,
    queryChange,
    unmountStore
}: reducerFunctions = conversationHistorySlice.actions;

export default conversationHistorySlice.reducer;
