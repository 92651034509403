import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserCredit, getUserPackage } from "../modules/packages/states/package.slice";

const AuthData = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUserPackage());
        dispatch(getUserCredit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
};
export default AuthData;
