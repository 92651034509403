interface props {
    isMultipleFileType?: boolean;
    fieldName?: string;
    [key: string]: any;
}

const jsonToFormData = (json: props) => {
    const formData = new FormData();
    for (let key in json) {
        if (Object.prototype.hasOwnProperty.call(json, key)) {
            const value = json[key];
            if (key === 'isMultipleFileType' || key === 'fieldName') {
                continue;
            }
            if (json['isMultipleFileType'] && key === json['fieldName']) {
                value.forEach((image: any) => {
                    formData.append(key, image);
                });
            }
            else {
                if (value instanceof File || value instanceof Blob) {
                    formData.append(key, value);
                } else if (typeof value === 'object') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            }
        }
    }
    return formData;
};

export default jsonToFormData;