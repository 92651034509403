import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import chatbotEndPoint from "../apiEndPoint.service.m";

class ConversationServiceClass {
    async getConversationUniqueList(payload: any) {
        return rootApi('GET', chatbotEndPoint.getConversationUniqueList);
    }
}

const ConversationService = new ConversationServiceClass();
export default ConversationService;