import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log("error", error, this.props.children);
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    console.log(error, info);
  }
  render() {
    if (this.state.hasError) {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <p>Something went wrong.</p>
          <p>Fail to load this module</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
