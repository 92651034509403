import { CustomAction, commonStatesP } from "./interface.state";

const commonReducers = {
    isGetting: (state: commonStatesP) => {
        state.isLoading = true;
    },
    isGettingSuccess: (state: commonStatesP, { payload }: CustomAction<any>) => {
        if (state.hasInfiniteScroll) {
            state.list = state.page === 1 ? [...payload] :
                (state.itemAppendDirection === 'first' ? [...payload, ...state.list] : [...state.list, ...payload])
            state.hasMore = payload.length >= state.perPage ? true : false;
            state.isLoadingMore = false;
        }
        else {
            state.list = [...payload]
        }
        state.refreshing = false;
        state.isLoading = false;
        state.firstRender = true;
    },
    isGettingError: (state: commonStatesP) => {
        state.isLoadingMore = false;
        state.refreshing = false;
        state.isLoading = false;
    },
    isGettingMore: (state: commonStatesP) => {
        state.isLoadingMore = true;
    },
    isRefresh: (state: commonStatesP) => {
        state.refreshing = true;
    },
    isStateUpdate: (state: commonStatesP, { payload }: CustomAction<any>) => {
        state = { ...state, ...payload }
    },
    pageChange: (state: commonStatesP, { payload }: CustomAction<any>) => {
        state.isLoading = true;
        state.hasMore = false;
        state.page = payload;
        state.list = [];

    },
    perPageChange: (state: commonStatesP, { payload }: CustomAction<any>) => {
        state.isLoading = true;
        state.hasMore = false;
        state.perPage = payload;
        state.list = [];
    },
    queryChange: (state: commonStatesP, { payload }: CustomAction<any>) => {
        state.isLoading = true;
        state.hasMore = false;
        state.query = payload;
        state.list = [];
    }
};

export default commonReducers;
