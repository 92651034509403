import { getApiToken } from "../../../helper/cookie/cookie";
import isEmpty from "../../../helper/utilities/IsEmpty";
import { messages } from "./const.root-api";
import errorHandler from "./errorHandler.root-api";
import returnResponse from "./returnResponse.root-api";

const apiToken = async (next: any, data: any) => {
    const token = getApiToken();
    if (isEmpty(token)) {
        errorHandler({ ...data }, messages.noToken);
        return returnResponse(null, false, messages.noToken, true);
    }
    return next({ ...data, token });
};

export default apiToken;