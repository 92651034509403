interface eachConversationPros {
    item: any;
    index: number;
    styles?: any;
}

const EachLead = ({ item, index, styles }: eachConversationPros) => {
    return (
        <>
            <tr key={index} className={styles.tr}>
                <td>{item.name}</td>
                <td style={{ textAlign: 'center' }}>{item.number}</td>
                <td style={{ textAlign: 'center' }}>{item.email}</td>
            </tr>
        </>
    )
};
export default EachLead;