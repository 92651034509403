/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { leftSideBarMenu, menuProps } from '../../../assets/tabs/menu.tab';
import EachMenu from './EachMenu.layout';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../app-component/buttons/CustomButton.c';
import { resetApiToken, resetProfileData } from '../../../helper/cookie/cookie';
import { useDispatch } from 'react-redux';
import { resetLogin } from '../../../modules/auth/states/auth.slice';

import styles from '../css/leftside.layout.module.css';
import isEmpty from '../../../helper/utilities/IsEmpty';
const { sidebarBody, scroll, menuFooter, logoutButtonAlt } = styles;

const getLocation = (pathName: string) => {
    pathName = pathName.replace('/', "");
    if (pathName) {
        const routes = pathName.split("/");
        if (!isEmpty(routes)) {
            return routes[0];
        }
    }
    return leftSideBarMenu[1].value;
}

const LeftSidebar = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selected, setSelected] = useState<number | string>(getLocation(location.pathname));

    useEffect(() => { setSelected(getLocation(location.pathname)) }, [location]);

    const changeMenu = useCallback((item: menuProps) => {
        setSelected(item.value)
        if (item.link) {
            navigate(item.link)
        }
    }, [])

    const handleLogout = () => {
        dispatch(resetLogin());
        resetApiToken();
        resetProfileData();
        navigate('login');
    };

    return (
        <div className={`${sidebarBody}`}>
            <div className={`${scroll} se-scroll-bar`}>
                {leftSideBarMenu.map((item: menuProps, index: number) => {
                    if (!item.isHide) {
                        return <EachMenu key={index} item={item} isSelected={selected === item.value} onClick={() => changeMenu(item)} styles={styles} />
                    }
                    return null;
                })}
            </div>
            <div className={`${menuFooter}`}>
                <CustomButton title='Logout' classes={logoutButtonAlt} onClick={handleLogout} />
            </div>
        </div>
    )
};
export default LeftSidebar;