import CoreSkeleton from "../../app-component/skeleton/CoreSkeleton";

const ChatbotPublicViewFallback = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 20, gap: 10 }}>
            <CoreSkeleton height="40px" width="65%" borderRadius="4px" style={{ alignSelf: 'flex-start' }} />
            <CoreSkeleton height="40px" width="55%" borderRadius="4px" style={{ alignSelf: 'flex-end' }} />
            <CoreSkeleton height="40px" width="35%" borderRadius="4px" style={{ alignSelf: 'flex-start' }} />
            <CoreSkeleton height="40px" width="59%" borderRadius="4px" style={{ alignSelf: 'flex-end' }} />
            <CoreSkeleton height="40px" width="45%" borderRadius="4px" style={{ alignSelf: 'flex-start' }} />
            <CoreSkeleton height="40px" width="30%" borderRadius="4px" style={{ alignSelf: 'flex-end' }} />
            <CoreSkeleton height="40px" width="60%" borderRadius="4px" style={{ alignSelf: 'flex-start' }} />
            <CoreSkeleton height="40px" width="40%" borderRadius="4px" style={{ alignSelf: 'flex-end' }} />
        </div>
    );
}
export default ChatbotPublicViewFallback;