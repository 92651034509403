import { lazy } from "react";
import Loadable from "../suspense-fallback/Loadable.suspense";
import { parentRoute } from "../interface.route";
import consoleRouteName from "../consoleRouteName.router";
import ConversationFallback from "../suspense-fallback/Conversation.fallback";
import LeadIndex from "../../modules/leads/Index.m";

const ConversationIndex = Loadable(lazy(() => import('../../modules/conversation/Index.m')), ConversationFallback);
const ConversationHistoryIndex = Loadable(lazy(() => import('../../modules/conversation-details/Index.m')), ConversationFallback);

const conversationRoutes: Array<parentRoute> = [
    {
        path: consoleRouteName.conversation.path,
        navigate: consoleRouteName.conversation.navigate,
        element: ConversationIndex,
        index: false,
        accessLevel: 0,
        title: "Conversation list"
    },
    {
        path: consoleRouteName.conversationDetails.path,
        navigate: consoleRouteName.conversationDetails.navigate,
        element: ConversationHistoryIndex,
        index: false,
        accessLevel: 0,
        title: "Conversation Details"
    },
    {
        path: consoleRouteName.leadList.path,
        navigate: consoleRouteName.leadList.navigate,
        element: LeadIndex,
        index: false,
        accessLevel: 0,
        title: "Lead List"
    },
]
export default conversationRoutes;
