import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import commonStates from "../../../states/commonState.state";
import commonReducers from "../../../states/commonReducer.state";
import { CustomAction } from "../../../states/interface.state";

const initialState: initialStateP = {
    ...commonStates,
    /* create new chatbot */
    isSaving: false,
    newItem: null
}

const chatbotSlice: any = createSlice({
    name: sliceName.chatbot,
    initialState: initialState,
    reducers: {
        ...commonReducers,
        createNewChatbot: (state: initialStateP) => {
            state.isSaving = true;
        },
        createNewChatbotSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.list = [...state.list, payload]
            state.newItem = payload;
            state.isSaving = false;
        },
        createNewChatbotFailed: (state: initialStateP) => {
            state.isSaving = false;
        },
        deleteChatbotItem: (state: initialStateP, { payload }: CustomAction<any>) => {
            const _list = [...state.list];
            _list.splice(payload, 1);
            state.list = [..._list];
        }
    },
});

export const {
    isGetting,
    isGettingSuccess,
    isGettingError,
    isGettingMore,
    isRefresh,
    isStateUpdate,
    pageChange,
    perPageChange,
    queryChange,
    deleteChatbotItem,
    createNewChatbot,
    createNewChatbotSuccess,
    createNewChatbotFailed
}: reducerFunctions = chatbotSlice.actions;

export default chatbotSlice.reducer;
