import React from "react";
import ListPageHeader from "./components/ListPageHeader.m";
import styles from './css/lead.module.css';
import LeadList from "./components/LeadList.m";

const LeadIndex: React.FC = () => {
    return (
        <div className={`${styles.container} se-scroll-bar`}>
            <ListPageHeader styles={styles} />
            <LeadList />
        </div>
    )
};

export default LeadIndex;