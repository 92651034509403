import { combineReducers } from "@reduxjs/toolkit";
import sliceName from "./sliceName.state";
import dashboardSlice from "../modules/dashboard/states/dashboard.slice";
import chatbotSlice from "../modules/chatbot/states/chatbot.slice";
import chatbotDetailsSlice from "../modules/chatbot-details/states/chatbotDetails.slice";
import authSlice from "../modules/auth/states/auth.slice";
import publicViewSlice from "../modules/chatbot-public-view/states/publicView.slice";
import conversationSlice from "../modules/conversation/states/conversation.slice";
import conversationHistorySlice from "../modules/conversation-details/states/conversationHistory.slice";
import companyInformationSlice from "../modules/company-information/states/companyInformation.slice";
import packageSlice from "../modules/packages/states/package.slice";

const rootReducer = combineReducers({
    [sliceName.auth]: authSlice,
    [sliceName.dashboard]: dashboardSlice,
    [sliceName.chatbot]: chatbotSlice,
    [sliceName.chatbotDetails]: chatbotDetailsSlice,
    [sliceName.publicView]: publicViewSlice,
    [sliceName.conversation]: conversationSlice,
    [sliceName.conversationHistory]: conversationHistorySlice,
    [sliceName.companyInformation]: companyInformationSlice,
    [sliceName.package]: packageSlice,
});

export default rootReducer;
