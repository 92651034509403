import toast from "react-hot-toast";
import { toasterProps } from "./interface.p";
import { getCurrentDateTimeMilliSeconds } from "../../helper/utilities/timeConverter";

const style: any = {
    minWidth: '250px',
    background: '#333',
    color: '#fff',
}

const toasterOption: any = {
    position: 'top-right',
    style: style,
    duration: 5000,
};


const toastLoading = ({ message, styles = style, position = 'top-right' }: toasterProps) => {
    const _id = getCurrentDateTimeMilliSeconds()
    toast.loading(message, { ...toasterOption, id: _id, style: styles, position });
    return _id;
}
const toastSuccess = ({ message, id, styles = style, position = 'top-right' }: toasterProps) => {
    toast.success(message, { ...toasterOption, id: id, style: styles, position });
}
const toastError = ({ message, id, styles = style, position = 'top-right' }: toasterProps) => {
    toast.error(message, { ...toasterOption, id: id, style: styles, position });
}
const toastDismiss = () => {
    toast.dismiss();
}

export { toastLoading, toastSuccess, toastError, toastDismiss }