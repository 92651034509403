import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { getUserCreditSuccess, getUserPackage, getUserPackageFailed, getUserPackageSuccess, goToDashboard, isGettingPackageListFailed, isGettingPackageListSuccess } from "./package.slice";
import { _buyPackageProps, _getPackageProps } from "../services/interface.service.m";
import PackageService from "../services/helpers/Package.service";

function* packages() {
    yield takeEvery(`${sliceName.package}/isGettingPackageList`, isGettingPackageListSaga);
    yield takeEvery(`${sliceName.package}/getUserPackage`, getUserPackageSaga);
    yield takeEvery(`${sliceName.package}/updatingPackage`, updatingPackageSaga);
    yield takeEvery(`${sliceName.package}/getUserCredit`, getUserCreditSaga);
}
function* isGettingPackageListSaga({ payload }: CustomPayload<_getPackageProps>): Generator {
    const result = yield call(PackageService.getPackageList, payload);
    if (!result) {
        yield put(isGettingPackageListFailed());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(isGettingPackageListSuccess(body));
        // if(payload.access === 'private'){
        //     yield put(getUserPackage());
        // }
    } else {
        yield put(isGettingPackageListFailed());
    }
}
function* getUserPackageSaga(): Generator {
    const result = yield call(PackageService.getUserSelectedPackage);
    if (!result) {
        yield put(getUserPackageFailed());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(getUserPackageSuccess(body));
    } else {
        yield put(getUserPackageFailed());
    }
}
function* updatingPackageSaga({ payload }: CustomPayload<_buyPackageProps>): Generator {
    console.log("payload", payload);
    const result = yield call(PackageService.buyPackage, payload);
    if (!result) {
        yield put(getUserPackageFailed());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(getUserPackageSuccess(body));
        if(payload.access === 'private'){
            yield put(goToDashboard(body));
        }
    } else {
        yield put(getUserPackageFailed());
    }
}
function* getUserCreditSaga(): Generator {
    const result = yield call(PackageService.getUserCreditBalance);
    if (result) {
        const { body, status } = result as apiResponse;
        if (status) {
            yield put(getUserCreditSuccess(body));
        }
    }
}

export default function* packageSaga() {
    yield all([packages()]);
}
