import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import conversationHistoryEndPoint from "../apiEndPoint.service.m";

class ConversationHistoryServiceClass {
    async getConversationHistory(payload: any) {
        return rootApi('GET', conversationHistoryEndPoint.getConversationHistory + "?uniqueConversationCode=" + payload);
    }
}

const ConversationHistoryService = new ConversationHistoryServiceClass();
export default ConversationHistoryService;