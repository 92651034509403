import { all, call, put, select, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import PublicViewService from "../services/helpers/PublicView.service";
import { createNewMessageFailed, createNewMessageSuccess, getDetailsFailed, getDetailsSuccess } from "./publicView.slice";
import { chatbotPublicViewStats } from "../../../states/allSelector.state";

function* publicView() {
    yield takeEvery(`${sliceName.publicView}/getDetailsById`, getDetailsSaga);
    yield takeEvery(`${sliceName.publicView}/getDetailsByCode`, getDetailsSaga);
    yield takeEvery(`${sliceName.publicView}/createNewMessage`, createNewMessageSaga);
}
function* getDetailsSaga({ payload }: CustomPayload<any>): Generator {
    const result = yield call(PublicViewService.getPublicViewDetails, { id: payload.code, tag: payload.tag });
    if (!result) {
        yield put(getDetailsFailed("Something went wrong !"));
        return;
    }
    const { body, status, message } = result as apiResponse;
    if (status) {
        yield put(getDetailsSuccess(body));
    } else {
        yield put(getDetailsFailed(message));
    }
}
function* createNewMessageSaga({ payload }: CustomPayload<any>): Generator {
    const { id, code, token }: any = yield select(chatbotPublicViewStats);
    const result = yield call(PublicViewService.createNewConversation, {
        id: id, code: code, uniqueConversationCode: token,
        message: payload.message, files: payload.files, isMultipleFileType: true, fieldName: 'files'
    });
    if (!result) {
        yield put(createNewMessageFailed("Something went wrong !"));
        return;
    }
    const { body, status, message } = result as apiResponse;
    if (status) {
        yield put(createNewMessageSuccess(body));
    } else {
        yield put(createNewMessageFailed(message));
    }
}

export default function* publicViewSaga() {
    yield all([publicView()]);
}
