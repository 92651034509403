import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";
import Chatbot from "../../../controllers/models/Chatbot.model";

const initialState: initialStateP = {
    id: null,
    uid: '',
    item: null,
    index: null,
    from: null,
    isLoadingDetails: true,
    details: {
        setup: {
            name: '',
            greetingType: 1,
            greetingPrompt: '',
            userInputPlaceholder: '',
            creativity: 0.7,
            showDisclosure: true,
            disclosureMessage: '',
            browserTitle: 'Chat',
            pageHeader: "",
            pageDescription: ""
        },
        instructions: '',
        actions: null,
        style: null,
        conversation: null,
    },
    tab: Chatbot.DETAILS_TAB_SETUP,
    isSaving: false,
    publicPreviewData: null
}

const chatbotDetailsSlice: any = createSlice({
    name: sliceName.chatbotDetails,
    initialState: initialState,
    reducers: {
        storeId: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.id = payload;
        },
        storeItem: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.item = payload.item;
            state.index = payload.index || null;
            state.from = payload.from || null;
            state.uid = payload.uid;
        },
        changeTab: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.tab = payload;
        },
        isGettingDetails: (state: initialStateP) => {
            state.isLoadingDetails = true;
        },
        isGettingDetailsSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            if (state.tab === 'instructions') {
                state.details.instructions = payload.instruction;
            }
            else {
                state.details[state.tab] = { ...state.details[state.tab], ...payload };
                state.publicPreviewData = { ...payload };
            }
            state.isLoadingDetails = false;
        },
        isGettingDetailsFailed: (state: initialStateP) => {
            state.isLoadingDetails = false;
        },
        storeDetailsData: (state: initialStateP) => {
            state.isSaving = true;
        },
        storeDetailsDataSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isSaving = false;
            if (state.tab === 'instructions') {
                state.details.instructions = payload;
            }
            else {
                state.details[state.tab] = { ...state.details[state.tab], ...payload };
                state.publicPreviewData = { ...payload };
            }
        },
        storeDetailsDataFailed: (state: initialStateP) => {
            state.isSaving = false;
        },
        unmountStore: (state: initialStateP) => {
            for (const property in initialState) {
                (state as any)[property] = (initialState as any)[property];
            }
        },
    },
});

export const {
    storeId,
    storeItem,
    changeTab,
    isGettingDetails,
    isGettingDetailsSuccess,
    isGettingDetailsFailed,
    storeDetailsData,
    storeDetailsDataSuccess,
    storeDetailsDataFailed,
    unmountStore
}: reducerFunctions = chatbotDetailsSlice.actions;

export default chatbotDetailsSlice.reducer;
