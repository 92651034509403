import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";

const initialState: initialStateP = {
    instruction: "",
    isGetting: true,
    isUpdating: false,
    /* new Data */
    formData: "",
}

const companyInformationSlice: any = createSlice({
    name: sliceName.companyInformation,
    initialState: initialState,
    reducers: {
        getCompanyInstruction: (state: initialStateP) => {
            state.isGetting = true;
        },
        getCompanyInstructionSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            console.log("payload", payload);
            state.instruction = payload.instruction;
            state.isGetting = false;
        },
        getCompanyInstructionFailed: (state: initialStateP) => {
            state.isGetting = false;
        },
        updateCompanyInstruction: (state: initialStateP) => {
            state.isUpdating = true;
        },
        updateCompanyInstructionSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.instruction = payload;
            state.isUpdating = false;
        },
        updateCompanyInstructionFailed: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isUpdating = false;
        },
        storeInstructionFormData: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.formData = payload;
        },
    },
});

export const {
    getCompanyInstruction,
    getCompanyInstructionSuccess,
    getCompanyInstructionFailed,
    updateCompanyInstruction,
    updateCompanyInstructionSuccess,
    updateCompanyInstructionFailed,
    storeInstructionFormData
}: reducerFunctions = companyInformationSlice.actions;

export default companyInformationSlice.reducer;
