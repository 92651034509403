import { lazy } from "react";
import Loadable from "../suspense-fallback/Loadable.suspense";
import { parentRoute } from "../interface.route";
import consoleRouteName from "../consoleRouteName.router";
import ChatbotFallback from "../suspense-fallback/Chatbot.fallback";
import ChatbotDetailsFallback from "../suspense-fallback/ChatbotDetails.fallback";

const ChatbotIndex = Loadable(lazy(() => import('../../modules/chatbot/Index.m')), ChatbotFallback);
const ChatbotDetailsIndex = Loadable(lazy(() => import('../../modules/chatbot-details/Index.m')), ChatbotDetailsFallback);

const chatbotRoutes: Array<parentRoute> = [
    {
        path: consoleRouteName.chatbot.path,
        navigate: consoleRouteName.chatbot.navigate,
        element: ChatbotIndex,
        index: false,
        accessLevel: 0,
        title: "Chatbot list"
    },
    {
        path: consoleRouteName.chatbotDetails.path,
        navigate: consoleRouteName.chatbotDetails.navigate,
        childNavigate: consoleRouteName.chatbotDetails.childNavigate,
        element: ChatbotDetailsIndex,
        index: false,
        accessLevel: 0,
        title: "Chatbot Details"
    },
]
export default chatbotRoutes;
