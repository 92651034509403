const returnResponse = (response: any, status: any, message: any, logout = false, extraData = null) => {
    return {
        status: status,
        body: response,
        message: message,
        logout: logout,
        extraData: extraData,
    };
};

export default returnResponse;