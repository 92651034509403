import CoreSkeleton from "../../app-component/skeleton/CoreSkeleton";

const ChatbotDetailsFallback = () => {
    return (
        <div>
            <CoreSkeleton />
            <CoreSkeleton />
            <CoreSkeleton />
            <CoreSkeleton />
        </div>
    );
};
export default ChatbotDetailsFallback;