import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { reducerFunctions, states, statsDataType, statsFilterType } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";

const statsData = {
    totalBots: 0,
    totalConversations: 0,
    totalLeads: 0,
    pendingConversations: 0
}
const filter: statsFilterType = {
    startDate: null,
    endDate: null,
    timeFrameType: 0,
    accessType: 'all'
}

const initialState: states = {
    isLoadingStats: false,
    statsData: { ...statsData },
    filter: { ...filter },
    isFiltering: false,
};

const dashboardSlice: any = createSlice({
    name: sliceName.dashboard,
    initialState: initialState,
    reducers: {
        getStats: (state: states) => {
            state.isLoadingStats = true;
        },
        getStatsSuccess: (state: states, { payload }: CustomAction<statsDataType>) => {
            state.statsData = { ...payload };
            state.isLoadingStats = false;
            state.isFiltering = false;
        },
        getStatsFailed: (state: states) => {
            state.isLoadingStats = false;
            state.isFiltering = false;
        },
        handleFilter: (state: states, payload: any) => {
            state.isFiltering = true;
            /* change filter option */
        },
    },
});

export const {
    getStats,
    getStatsSuccess,
    getStatsFailed,
    handleFilter
}: reducerFunctions = dashboardSlice.actions;

export default dashboardSlice.reducer;
