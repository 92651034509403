import { lazy } from "react";
import Loadable from "../suspense-fallback/Loadable.suspense";
import { parentRoute } from "../interface.route";
import consoleRouteName from "../consoleRouteName.router";
import ChatbotFallback from "../suspense-fallback/Chatbot.fallback";
import ChatbotDetailsFallback from "../suspense-fallback/ChatbotDetails.fallback";

const ChatbotIndex = Loadable(lazy(() => import('../../modules/chatbot/Index.m')), ChatbotFallback);
const PrivatePackageIndex = Loadable(lazy(() => import('../../modules/packages/PrivatePackageIndex.m')), ChatbotDetailsFallback);
const CardInfoIndex = Loadable(lazy(() => import('../../modules/packages/CardInfoIndex.m')), ChatbotDetailsFallback);

const profileAndSubscriptionRoutes: Array<parentRoute> = [
    {
        path: consoleRouteName.profile.path,
        navigate: consoleRouteName.profile.navigate,
        element: ChatbotIndex,
        index: false,
        accessLevel: 0,
        title: "My Profile"
    },
    {
        path: consoleRouteName.subscription.path,
        navigate: consoleRouteName.subscription.navigate,
        element: PrivatePackageIndex,
        index: false,
        accessLevel: 0,
        title: "Subscription plan"
    },
    {
        path: consoleRouteName.credit.path,
        navigate: consoleRouteName.credit.navigate,
        element: CardInfoIndex,
        index: false,
        accessLevel: 0,
        title: "Credit"
    },
]
export default profileAndSubscriptionRoutes;
