import sliceName from "./sliceName.state";

export const authStates = (state: any) => state[sliceName.auth];
export const dashboardStates = (state: any) => state[sliceName.dashboard];
export const chatbotStates = (state: any) => state[sliceName.chatbot];
export const chatbotDetailsStates = (state: any) => state[sliceName.chatbotDetails];
export const chatbotPublicViewStats = (state: any) => state[sliceName.publicView];
export const conversationStates = (state: any) => state[sliceName.conversation];
export const conversationHistoryStates = (state: any) => state[sliceName.conversationHistory];
export const companyInformationStates = (state: any) => state[sliceName.companyInformation];
export const packageStates = (state: any) => state[sliceName.package];

