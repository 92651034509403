import { Navigate, Route } from "react-router-dom";
import consoleRouteName from "./consoleRouteName.router";
import { parentRoute } from "./interface.route";
import ConsolePrivateLayout from "./layouts/ConsolePrivate.layout.m";
import chatbotRoutes from "./modules/chatbot.route";
import conversationRoutes from "./modules/conversation.route";
import companyRoutes from "./modules/company.route";
import profileAndSubscriptionRoutes from "./modules/profileAndSubscription.route";

const renderConsolePrivateRoutes = () => {
    return (
        <Route
            path={consoleRouteName.root.path}
            element={<ConsolePrivateLayout />}
        >
            <Route index={true} path={''} element={<Navigate to={consoleRouteName.chatbot.path} />} />
            {chatbotRoutes.map((item: parentRoute, index: number) => (
                <Route index={item.index} path={item.path} element={<item.element {...item} />} key={index} />
            ))}
            {conversationRoutes.map((item: parentRoute, index: number) => (
                <Route index={item.index} path={item.path} element={<item.element {...item} />} key={index} />
            ))}
            {companyRoutes.map((item: parentRoute, index: number) => (
                <Route index={item.index} path={item.path} element={<item.element {...item} />} key={index} />
            ))}
            {profileAndSubscriptionRoutes.map((item: parentRoute, index: number) => (
                <Route index={item.index} path={item.path} element={<item.element {...item} />} key={index} />
            ))}
        </Route>
    );
};
export default renderConsolePrivateRoutes;