import { all, call, put, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import { isGettingError, isGettingSuccess } from "./conversationHistory.slice";
import ConversationHistoryService from "../services/helpers/ConversationHistory.service";

function* conversationHistory() {
    yield takeEvery(`${sliceName.conversationHistory}/isGetting`, isGettingSaga);
}
function* isGettingSaga({ payload }: CustomPayload<any>): Generator {
    const result = yield call(ConversationHistoryService.getConversationHistory, payload);
    if (!result) {
        yield put(isGettingError());
        return;
    }
    const { body, status } = result as apiResponse;
    if (status) {
        yield put(isGettingSuccess(body));
    } else {
        yield put(isGettingError());
    }
}

export default function* conversationHistorySaga() {
    yield all([conversationHistory()]);
}
