import { baseURL, moduleName } from "../../../controllers/api/globalEndpoint.api";

const packageEndPoint = {
    getPackageList: baseURL + moduleName.package + '/list',
    getUserPackage: baseURL + '/user/has/package/check/data',
    buyPackage: baseURL + moduleName.package + '/buy',
    creditBalance: baseURL + '/user/balance/key/wise/data',
    getInvoiceData: baseURL + moduleName.invoice + '/get/all/data',
    getTopUpData: baseURL + moduleName.invoice + '/get/all/recharge/top/up/data',
    submitTopUp: baseURL + moduleName.recharge + '/balance',
    /* auto recharge */
    getAmountData: baseURL + moduleName.recharge + '/get/amount/data',
    getChargeAmountData: baseURL + moduleName.recharge + '/get/charge/amount/data',
    submitAutoRecharge: baseURL + moduleName.recharge + '/balance',
};

export default packageEndPoint;