import { Suspense } from "react";
import SuspenseFallback from "./SuspenseFallback.fallback";
import ErrorBoundary from "./ErrorBoundary";

const Loadable = (Component: any, Fallback: any = SuspenseFallback) => (props: any) => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Fallback />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default Loadable;