import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import leadEndPoint from "../apiEndPoint.service.m";

class LeadServiceClass {
    async getList(payload: any) {
        return rootApi('GET', leadEndPoint.getList);
    }
}

const LeadService = new LeadServiceClass();
export default LeadService;