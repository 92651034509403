import React, { useRef } from "react";
import { props } from "./interface/private.interface.m";
import styles from './css/private.module.css';
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthTopBar from "./components/AuthTopBar.layout";
import LeftSidebar from "./components/LeftSidebar.layout";
import { getApiToken } from "../../helper/cookie/cookie";
import { useSelector } from "react-redux";
import { packageStates } from "../../states/allSelector.state";
import isEmpty from "../../helper/utilities/IsEmpty";
import consoleRouteName from "../consoleRouteName.router";
import { leftSideBarMenu } from "../../assets/tabs/menu.tab";

const getLocation = (pathName: string) => {
    pathName = pathName.replace('/', "");
    if (pathName) {
        const routes = pathName.split("/");
        if (!isEmpty(routes)) {
            return routes[0];
        }
    }
    return leftSideBarMenu[1].value;
}

const ConsolePrivateLayout: React.FC<props> = () => {
    const location = useLocation();
    const {isGettingUserPackage, subscribedPackage} = useSelector(packageStates);
    const tokenRef = useRef(getApiToken());

    const pathName = getLocation(location.pathname);

    if (tokenRef.current) {
        if(isGettingUserPackage){
            return (
                <div className={styles.container}>
                    <span>Please wait...</span>
                </div>
            );
        }
        if(!isGettingUserPackage && isEmpty(subscribedPackage) && pathName !== "subscription"){
            return <Navigate replace to={consoleRouteName.subscription.path} />;
        }
        return (
            /* maintain loading state for skeleton later */
            <div className={styles.container}>
                <div className={styles.sidebarWrp}>
                    <AuthTopBar />
                    <LeftSidebar />
                </div>
                <div className={styles.body}>
                    <div className={styles.children}>
                        <Outlet />
                    </div>
                </div>
            </div>
        );
    }
    return <Navigate replace to='/login' />;

};
export default ConsolePrivateLayout;