import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import companyInformationEndPoint from "../apiEndPoint.service.m";
import { updateCompanyInformation } from "../interface.service.m";

class CompanyInformationServiceClass {
    async getCompanyInformation() {
        return rootApi('GET', companyInformationEndPoint.getCompanyInformation);
    }
    async updateCompanyInformation(payload: updateCompanyInformation) {
        return rootApi('POST', companyInformationEndPoint.updateCompanyInformation, payload);
    }
}

const CompanyInformationService = new CompanyInformationServiceClass();
export default CompanyInformationService;